import React, { createContext, useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';

export const OrganizationContext = createContext();

export const OrganizationProvider = ({ children }) => {
  const [organization, setOrganization] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchOrganizationDetails = async () => {
      try {
        const response = await axios.get('https://api.thriftforge.com/organization');
        setOrganization(response.data);
        setError(false);
      } catch (error) {
        console.error('Error fetching organization details:', error.message);
        setError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrganizationDetails();
  }, []);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-50">
        <p className="text-gray-500">Loading organization data...</p>
      </div>
    );
  }

  if (error) {
    return <Navigate to="/login" replace />;
  }

  return (
    <OrganizationContext.Provider value={organization}>
      {children}
    </OrganizationContext.Provider>
  );
};
