import React, { useState, useEffect, useCallback, useContext } from 'react'
import { useParams, Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import Header from './Header'
import Footer from './Footer'
import debounce from 'lodash/debounce'
import { OrganizationContext } from '../context/OrganizationContext'

const ProductPage = ({ onSignOut }) => {
  const organization = useContext(OrganizationContext);
  const [products, setProducts] = useState([])
  const [closet, setCloset] = useState(null)
  const [totalCount, setTotalCount] = useState(0)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(20)
  const [filters, setFilters] = useState({})
  const { closetId } = useParams()
  const navigate = useNavigate();
  const [deletingProductId, setDeletingProductId] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      const queryParams = new URLSearchParams({
        closet_id: closetId,
        page,
        page_size: pageSize,
      });

      Object.entries(filters).forEach(([key, value]) => {
        if (value) {
          queryParams.append(key, value);
        }
      });

      const [closetResponse, productsResponse] = await Promise.all([
        axios.get(`https://api.thriftforge.com/closets/${closetId}`),
        axios.get(`https://api.thriftforge.com/closets/${closetId}/products/search?${queryParams}`)
      ]);

      setCloset(closetResponse.data);
      setProducts(productsResponse.data.products);
      setTotalCount(productsResponse.data.total_count);
    } catch (error) {
      console.error('Error fetching data:', error.message);
    }
  }, [closetId, page, pageSize, filters]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleFilterChange = useCallback(
    debounce((key, value) => {
      setFilters(prevFilters => ({ ...prevFilters, [key]: value }))
      setPage(1) // Reset to first page when filter changes
    }, 300),
    []
  )

  const renderSelectFilter = (key, options, placeholder) => (
    <select
      className="w-full p-1 text-sm border rounded"
      onChange={(e) => handleFilterChange(key, e.target.value)}
    >
      <option value="">{placeholder}</option>
      {options.map(option => (
        <option key={option.id} value={option.id}>
          {option.name || option.Name}
        </option>
      ))}
    </select>
  )

  const renderFilterInput = (key, placeholder) => (
    <input
      type="text"
      placeholder={placeholder}
      className="w-full p-1 text-sm border rounded"
      onChange={(e) => handleFilterChange(key, e.target.value)}
    />
  )

  const formatDate = (dateString) => {
    if (!dateString || dateString.startsWith('0000')) return 'N/A';
    return new Date(dateString).toLocaleDateString();
  }

  const formatPrice = (price) => {
    if (price === null || price === undefined) return 'N/A';
    const dollars = price / 100000; // Convert millicents to dollars
    return `$${dollars.toFixed(2)}`;
  }

  const formatCategories = (categories) => {
    return categories ? categories.map(c => c.name).join(', ') : 'N/A';
  }

  const handleRowClick = (productId) => {
    navigate(`/closet/${closetId}/products/${productId}`);
  };

  const getFirstImageUrl = (images) => {
    if (!images || images.length === 0) return null;
    return images[0].location;
  }

  const handleDeleteProduct = async (productId, event) => {
    event.stopPropagation(); // Prevent row click event
    try {
      await axios.delete(`https://api.thriftforge.com/closets/${closetId}/products/${productId}`);
      setDeletingProductId(null);
      fetchData(); // Refresh the product list
    } catch (error) {
      console.error('Error deleting product:', error.message);
    }
  };

  const handleDeleteClick = (productId, event) => {
    event.stopPropagation();
    setDeletingProductId(productId);
  };

  const handleRefresh = async () => {
    setIsRefreshing(true);
    await fetchData();
    setIsRefreshing(false);
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
      <Header onSignOut={onSignOut} />
      <main className="flex-grow container mx-auto py-6 px-4">
        {/* Header Section */}
        <div className="mb-8">
          <Link to="/dashboard" className="text-sm text-gray-600 hover:text-indigo-600 flex items-center gap-1 mb-4">
            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
            Back to Dashboard
          </Link>
          <div className="flex justify-between items-center">
            <h2 className="text-2xl font-semibold text-gray-800">
              {closet ? closet.name : 'Loading...'}
            </h2>
            <div className="text-sm text-gray-500">
              {totalCount} total products
            </div>
          </div>
        </div>

        {/* Filters Section */}
        <div className="bg-white rounded-lg shadow-sm mb-6">
          <div className="p-4 border-b flex justify-between items-center">
            <h3 className="text-lg font-medium text-gray-800">Filters</h3>
            <button
              onClick={handleRefresh}
              className="p-2 text-gray-600 hover:text-indigo-600 transition-colors"
              disabled={isRefreshing}
            >
              <svg
                className={`w-5 h-5 ${isRefreshing ? 'animate-spin' : ''}`}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                />
              </svg>
            </button>
          </div>
          <div className="p-4">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
              <div className="space-y-1">
                <label className="text-sm text-gray-600">Category</label>
                {renderSelectFilter('category_id', organization.categories, 'All categories')}
              </div>
              <div className="space-y-1">
                <label className="text-sm text-gray-600">Platform</label>
                {renderSelectFilter('platform_sold_on_id', organization.platforms, 'All platforms')}
              </div>
              <div className="space-y-1">
                <label className="text-sm text-gray-600">Condition</label>
                {renderSelectFilter('condition_id', organization.conditions, 'All conditions')}
              </div>
              <div className="space-y-1">
                <label className="text-sm text-gray-600">Availability</label>
                {renderSelectFilter('availability_id', organization.availabilities, 'All availabilities')}
              </div>
            </div>
            
            <div className="mt-4 grid grid-cols-2 md:grid-cols-4 gap-4">
              <div className="space-y-1">
                <label className="text-sm text-gray-600">Source</label>
                {renderSelectFilter('source_id', organization.sources, 'All sources')}
              </div>
              <div className="space-y-1">
                <label className="text-sm text-gray-600">Price Range</label>
                <div className="flex gap-2">
                  {renderFilterInput('price_min', 'Min')}
                  {renderFilterInput('price_max', 'Max')}
                </div>
              </div>
              <div className="space-y-1">
                <label className="text-sm text-gray-600">Date Range</label>
                <div className="flex gap-2">
                  {renderFilterInput('date_listed', 'From')}
                  {renderFilterInput('date_purchased', 'To')}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Products Table */}
        <div className="bg-white rounded-lg shadow-sm overflow-hidden">
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Image
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Title
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Brand
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Categories
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Size
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Color
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Condition
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Cost
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Sold Price
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Source
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Date Purchased
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Date Listed
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Days Listed
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Net Profit
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Platform Sold On
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Availability
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {products.map((product) => (
                  <tr
                    key={product.id}
                    className="hover:bg-gray-50 transition-colors cursor-pointer"
                    onClick={() => handleRowClick(product.id)}
                  >
                    <td className="px-4 py-3 text-sm text-gray-900 whitespace-nowrap">
                      {getFirstImageUrl(product.images) ? (
                        <img 
                          src={`https://images.thriftforge.com/${getFirstImageUrl(product.images)}`} 
                          alt={product.title} 
                          className="w-12 h-12 object-cover rounded"
                        />
                      ) : (
                        <div className="w-12 h-12 bg-gray-100 rounded flex items-center justify-center">
                          <span className="text-gray-400 text-xs">No image</span>
                        </div>
                      )}
                    </td>
                    <td className="px-4 py-3 text-sm text-gray-900 whitespace-nowrap">
                      {product.title || 'N/A'}
                    </td>
                    <td className="px-4 py-3 text-sm text-gray-900 whitespace-nowrap">
                      {product.brand || 'N/A'}
                    </td>
                    <td className="px-4 py-3 text-sm text-gray-900 whitespace-nowrap">
                      {formatCategories(product.categories)}
                    </td>
                    <td className="px-4 py-3 text-sm text-gray-900 whitespace-nowrap">
                      {product.size || 'N/A'}
                    </td>
                    <td className="px-4 py-3 text-sm text-gray-900 whitespace-nowrap">
                      {product.color || 'N/A'}
                    </td>
                    <td className="px-4 py-3 text-sm text-gray-900 whitespace-nowrap">
                      {product.condition?.name || 'N/A'}
                    </td>
                    <td className="px-4 py-3 text-sm text-gray-900 whitespace-nowrap">
                      {formatPrice(product.cost)}
                    </td>
                    <td className="px-4 py-3 text-sm text-gray-900 whitespace-nowrap">
                      {formatPrice(product.soldPrice)}
                    </td>
                    <td className="px-4 py-3 text-sm text-gray-900 whitespace-nowrap">
                      {product.source?.name || 'N/A'}
                    </td>
                    <td className="px-4 py-3 text-sm text-gray-900 whitespace-nowrap">
                      {formatDate(product.datePurchased)}
                    </td>
                    <td className="px-4 py-3 text-sm text-gray-900 whitespace-nowrap">
                      {formatDate(product.dateListed)}
                    </td>
                    <td className="px-4 py-3 text-sm text-gray-900 whitespace-nowrap">
                      {product.daysListed || 'N/A'}
                    </td>
                    <td className="px-4 py-3 text-sm text-gray-900 whitespace-nowrap">
                      {formatPrice(product.netProfit)}
                    </td>
                    <td className="px-4 py-3 text-sm text-gray-900 whitespace-nowrap">
                      {product.platformSoldOn || 'N/A'}
                    </td>
                    <td className="px-4 py-3 text-sm text-gray-900 whitespace-nowrap">
                      {product.availability?.name || 'N/A'}
                    </td>
                    <td className="px-4 py-3 text-sm text-gray-900 whitespace-nowrap">
                      {product.isDraft ? 'Draft' : 'Published'}
                    </td>
                    <td className="px-4 py-3 text-sm text-gray-900 whitespace-nowrap">
                      {deletingProductId === product.id ? (
                        <div className="flex items-center gap-2">
                          <button
                            onClick={(e) => handleDeleteProduct(product.id, e)}
                            className="text-red-600 hover:text-red-800"
                          >
                            Confirm
                          </button>
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              setDeletingProductId(null);
                            }}
                            className="text-gray-600 hover:text-gray-800"
                          >
                            Cancel
                          </button>
                        </div>
                      ) : (
                        <button
                          onClick={(e) => handleDeleteClick(product.id, e)}
                          className="text-red-600 hover:text-red-800"
                        >
                          Delete
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pagination */}
          <div className="px-4 py-3 border-t border-gray-200 bg-gray-50">
            <div className="flex items-center justify-between">
              <p className="text-sm text-gray-700">
                Showing {(page - 1) * pageSize + 1} - {Math.min(page * pageSize, totalCount)} of {totalCount} products
              </p>
              <div className="flex gap-2">
                <button
                  onClick={() => setPage(prev => Math.max(prev - 1, 1))}
                  disabled={page === 1}
                  className="px-4 py-2 text-sm font-medium rounded-md text-gray-700 bg-white border border-gray-300 hover:bg-gray-50 disabled:bg-gray-100 disabled:text-gray-400"
                >
                  Previous
                </button>
                <button
                  onClick={() => setPage(prev => prev + 1)}
                  disabled={page * pageSize >= totalCount}
                  className="px-4 py-2 text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 disabled:bg-indigo-300"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  )
}

export default ProductPage
