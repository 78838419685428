import React from 'react'
import logo from '../assets/logo.webp'

const Header = ({ onSignOut }) => (
  <header className="bg-white border-b p-3">
    <div className="container mx-auto flex justify-between items-center">
      <div className="flex items-center gap-2">
        <img src={logo} alt="Logo" className="h-8 w-auto" />
        <h1 className="text-lg text-gray-700">Thriftforge</h1>
      </div>
      <button
        onClick={onSignOut}
        className="px-3 py-1.5 text-sm text-gray-600 hover:text-gray-800 transition"
      >
        Sign Out
      </button>
    </div>
  </header>
)

export default Header
