import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'
import { OrganizationContext } from '../context/OrganizationContext'

const Dashboard = ({ onSignOut }) => {
  const organization = useContext(OrganizationContext);
  const navigate = useNavigate()

  const handleClosetSelect = (closetId) => {
    navigate(`/closet/${closetId}/products`)
  }

  return (
    organization ? (
      <div className="flex flex-col min-h-screen bg-gray-50">
        <Header onSignOut={onSignOut} />
        <main className="flex-grow container mx-auto py-8 px-4 max-w-4xl">
          <div className="bg-white rounded-lg shadow-sm p-6 mb-8">
            <div className="flex items-center justify-between mb-6">
              <h2 className="text-2xl font-semibold text-gray-800">Your Closets</h2>
              <span className="text-sm text-gray-500">
                {organization.organization.closets.length} total
              </span>
            </div>
          
            {organization.organization.closets.length > 0 ? (
              <div className="grid gap-6 md:grid-cols-2">
                {organization.organization.closets.map((closet) => (
                  <div 
                    key={closet.ID} 
                    className="border rounded-lg p-5 cursor-pointer hover:bg-gray-50 transition-colors hover:border-gray-300 hover:shadow-sm"
                    onClick={() => handleClosetSelect(closet.id)}
                  >
                    <div className="flex items-center justify-between mb-2">
                      <h4 className="text-lg font-medium text-gray-800">{closet.name}</h4>
                      <svg className="w-5 h-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                      </svg>
                    </div>
                    <p className="text-sm text-gray-600">{closet.description}</p>
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-center py-12 bg-gray-50 rounded-lg">
                <p className="text-gray-500 text-base mb-2">You don't have any closets yet.</p>
                <p className="text-sm text-gray-400">Create your first closet to get started</p>
              </div>
            )}
          </div>
        </main>
        <Footer />
      </div>
    ) : (
      <div className="flex items-center justify-center min-h-screen bg-gray-50">
        <p className="text-gray-500">Loading...</p>
      </div>
    )
  )
}

export default Dashboard
