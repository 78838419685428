import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => (
  <footer className="bg-white border-t">
    <div className="container mx-auto px-4 py-8">
      {/* Main Footer Content */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
        {/* Company Info */}
        <div className="space-y-4">
          <h3 className="text-sm font-semibold text-gray-900">Our App</h3>
          <p className="text-sm text-gray-600">
            Simplifying inventory management for resellers.
          </p>
        </div>

        {/* Quick Links */}
        <div className="space-y-4">
          <h3 className="text-sm font-semibold text-gray-900">Quick Links</h3>
          <ul className="space-y-2">
            <li>
              <Link to="/dashboard" className="text-sm text-gray-600 hover:text-indigo-600">
                Dashboard
              </Link>
            </li>
            <li>
              <Link to="/profile" className="text-sm text-gray-600 hover:text-indigo-600">
                Profile
              </Link>
            </li>
            <li>
              <Link to="/settings" className="text-sm text-gray-600 hover:text-indigo-600">
                Settings
              </Link>
            </li>
          </ul>
        </div>

        {/* Support */}
        <div className="space-y-4">
          <h3 className="text-sm font-semibold text-gray-900">Support</h3>
          <ul className="space-y-2">
            <li>
              <a href="/help" className="text-sm text-gray-600 hover:text-indigo-600">
                Help Center
              </a>
            </li>
            <li>
              <a href="/contact" className="text-sm text-gray-600 hover:text-indigo-600">
                Contact Us
              </a>
            </li>
            <li>
              <a href="/faq" className="text-sm text-gray-600 hover:text-indigo-600">
                FAQ
              </a>
            </li>
          </ul>
        </div>

        {/* Legal */}
        <div className="space-y-4">
          <h3 className="text-sm font-semibold text-gray-900">Legal</h3>
          <ul className="space-y-2">
            <li>
              <a href="/privacy" className="text-sm text-gray-600 hover:text-indigo-600">
                Privacy Policy
              </a>
            </li>
            <li>
              <a href="/terms" className="text-sm text-gray-600 hover:text-indigo-600">
                Terms of Service
              </a>
            </li>
          </ul>
        </div>
      </div>

      {/* Bottom Bar */}
      <div className="mt-8 pt-8 border-t border-gray-200">
        <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
          <p className="text-sm text-gray-600">
            © {new Date().getFullYear()} Our App. All rights reserved.
          </p>
          <div className="flex space-x-6">
            <a href="https://twitter.com" className="text-gray-400 hover:text-gray-500">
              <span className="sr-only">Twitter</span>
              <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
              </svg>
            </a>
            <a href="https://github.com" className="text-gray-400 hover:text-gray-500">
              <span className="sr-only">GitHub</span>
              <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                <path fillRule="evenodd" d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm0 18c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8-3.582 8-8 8z" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
