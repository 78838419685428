import './index.css'
import { useState, useEffect, useCallback } from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import axios from 'axios'
import SignIn from './components/SignIn'
import Dashboard from './components/Dashboard'
import ProductPage from './components/ProductPage'
import ProductDetailsPage from './components/ProductDetailsPage'
import { OrganizationProvider } from './context/OrganizationContext'
import SignUp from './components/SignUp'

export default function App() {
  const [session, setSession] = useState(() => {
    const token = localStorage.getItem('token');
    return token || null;
  });
  const [authError, setAuthError] = useState(null);

  const handleSignOut = useCallback(() => {
    localStorage.removeItem('token')
    delete axios.defaults.headers.common['Authorization']
    setSession(null)
  }, [])

  useEffect(() => {
    if (session) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${session}`;
    } else {
      delete axios.defaults.headers.common['Authorization'];
    }

    // Add a response interceptor
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          handleSignOut()
          setAuthError('You have been signed out. Please sign in again.')
        }
        return Promise.reject(error)
      }
    );

    // Clean up function
    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, [session, handleSignOut]);

  const handleSignIn = (token) => {
    localStorage.setItem('token', token)
    setSession(token)
    setAuthError(null)
  }

  const handleSignUp = (token) => {
    localStorage.setItem('token', token)
    setSession(token)
    setAuthError(null)
  }

  console.log(session)
  
  return (
    <Router>
      <div className="min-h-screen bg-gray-100">
        <Routes>
          <Route 
            path="/signup" 
            element={
              !session ? (
                <SignUp onSignUp={handleSignUp} authError={authError} />
              ) : (
                <Navigate to="/dashboard" />
              )
            } 
          />
          <Route 
            path="/login" 
            element={
              !session ? (
                <SignIn onSignIn={handleSignIn} authError={authError} />
              ) : (
                <Navigate to="/dashboard" />
              )
            } 
          />
          <Route
            path="/*"
            element={
              session ? (
                <OrganizationProvider>
                  <Routes>
                    <Route path="/" element={<Navigate to="/dashboard" />} />
                    <Route path="/dashboard" element={<Dashboard onSignOut={handleSignOut} />} />
                    <Route path="/closet/:closetId/products" element={<ProductPage onSignOut={handleSignOut} />} />
                    <Route path="/closet/:closetId/products/:productId" element={<ProductDetailsPage onSignOut={handleSignOut} />} />
                  </Routes>
                </OrganizationProvider>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
        </Routes>
      </div>
    </Router>
  )
}
